import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs';

import { PEI_ICON, PXE_ICON, PEM_ICON, PELV_ICON, PES_ICON, EPG_ICON, environment } from 'util';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        MatSnackBarModule,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    #lastCheckTime: number = 0;
    readonly #CHECK_INTERVAL_MS: number = 10 * 60 * 1000; // 10 minutes

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private swUpdate: SwUpdate,
        private snackBar: MatSnackBar,
        private router: Router,
    ) {
        this.matIconRegistry.addSvgIconLiteral(
            'pei',
            this.domSanitizer.bypassSecurityTrustHtml(PEI_ICON)
        );
        this.matIconRegistry.addSvgIconLiteral(
            'pxe',
            this.domSanitizer.bypassSecurityTrustHtml(PXE_ICON)
        );
        this.matIconRegistry.addSvgIconLiteral(
            'pem',
            this.domSanitizer.bypassSecurityTrustHtml(PEM_ICON)
        );
        this.matIconRegistry.addSvgIconLiteral(
            'pelv',
            this.domSanitizer.bypassSecurityTrustHtml(PELV_ICON)
        );
        this.matIconRegistry.addSvgIconLiteral(
            'pes',
            this.domSanitizer.bypassSecurityTrustHtml(PES_ICON)
        );
        this.matIconRegistry.addSvgIconLiteral(
            'epg',
            this.domSanitizer.bypassSecurityTrustHtml(EPG_ICON)
        );

        if (!environment.local && this.swUpdate.isEnabled) {
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(() => {
                    const currentTime = Date.now();
                    if (currentTime - this.#lastCheckTime > this.#CHECK_INTERVAL_MS) {
                        this.#lastCheckTime = currentTime;
                        this.checkForUpdates();
                    }
                });
        }
    }

    protected checkForUpdates() {
        this.swUpdate.checkForUpdate().then(isUpdateAvailable => {
            if (isUpdateAvailable) {
                this.showUpdateNotification();
            }
        });
    }

    protected showUpdateNotification() {
        const snackBarRef = this.snackBar.open('New site version available', 'Update', {
            duration: 20000,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
        });

        snackBarRef.onAction().subscribe(() => {
            this.swUpdate.activateUpdate().then(() => {
                window.location.reload();
            });
        });
    }
}
