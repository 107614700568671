<app-breadcrumb [breadcrumbs]="breadcrumbs$ | async"/>

<h1 class="page-title">My Advertisers</h1>

<mat-divider class="page-title-divider"></mat-divider>

@if ((dataToDisplay$ | async)?.length === 0) {
    <div class="no-data">
        You currently are not associated with any advertisers.
    </div>
}

<app-wrapper-table matSort #sort="matSort" [tableSort]="sort"
                   [dataToDisplay]="dataToDisplay$ | async"
                   [hideIfEmpty]="true"
>
    <app-advertiser-autocomplete filter-input
                                 context="internalPage"
                                 [advertiserSubset]="dataToDisplay$ | async"
                                 (advertiserSelected)="handleAdvertiserSelected($event)"
    ></app-advertiser-autocomplete>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser Name </th>
        <td mat-cell *matCellDef="let element">
            <a [routerLink]="'/advertiser/' + (element | asType : Advertiser).aid + '/upcoming-campaigns'">
                {{ (element | asType : Advertiser).name }}
            </a>
        </td>
    </ng-container>

    <ng-container matColumnDef="brands">
        <th mat-header-cell *matHeaderCellDef> Brands </th>
        <td mat-cell *matCellDef="let element">
            {{ (element | asType : Advertiser).brands | brands }}
        </td>
    </ng-container>

    @if (!(user$ | async)?.isAdvertiser) {
        <ng-container matColumnDef="salesforce">
            <th mat-header-cell *matHeaderCellDef> Salesforce</th>
            <td mat-cell *matCellDef="let element">
                @if ((element | asType : Advertiser).salesForceUrl; as salesForceUrl) {
                    <a mat-button color="primary" [href]="salesForceUrl" target="_blank">
                        <mat-icon aria-hidden="false">open_in_new</mat-icon>
                    </a>
                }
            </td>
        </ng-container>
    }

    <ng-container matColumnDef="engagementReports">
        <th mat-header-cell *matHeaderCellDef> Engagement Reports </th>
        <td mat-cell *matCellDef="let element">
            @if ((element | asType: Advertiser).baseIdEncrypted || (element | asType: Advertiser).mundoBaseIdEncrypted) {
                <div fxLayout="column">
                    @if ((element | asType: Advertiser).baseIdEncrypted) {
                        <div>
                            <a mat-button color="primary"
                               [href]="'https://engagement.pmmimediagroup.com/' + (element | asType: Advertiser).baseIdEncrypted" target="_blank">
                                REPORTS <mat-icon iconPositionEnd>open_in_new</mat-icon>
                            </a>
                        </div>
                    }
                    @if ((element | asType: Advertiser).mundoBaseIdEncrypted) {
                        <div>
                            <a mat-button color="primary"
                               [href]="'https://engagement.mundoexpopack.com/' + (element | asType: Advertiser).mundoBaseIdEncrypted" target="_blank">
                                MUNDO REPORTS <mat-icon iconPositionEnd>open_in_new</mat-icon>
                            </a>
                        </div>
                    }
                </div>
            }
        </td>
    </ng-container>

    @if (!(user$ | async)?.isAdvertiser) {
        <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef> Update</th>
            <td mat-cell *matCellDef="let element">
                <a mat-button color="primary"
                   [href]="internalUrl + '/advertiser/update?id=' + (element | asType : Advertiser).aid">
                    <mat-icon aria-hidden="false">edit</mat-icon>
                </a>
            </td>
        </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="(columns$ | async) || []"></tr>
    <tr mat-row *matRowDef="let row; columns: (columns$ | async) || []"></tr>
</app-wrapper-table>
