<app-breadcrumb [breadcrumbs]="breadcrumbs$ | async"/>

<h1 class="page-title">Reports & Tools</h1>

<mat-divider class="page-title-divider"></mat-divider>

@if (!(advertiser$ | async)) {
    <div class="loader">
        <ngx-skeleton-loader count="8" appearance="line" [theme]="{height: '64px'}"></ngx-skeleton-loader>
    </div>
}

<app-card-list>
    @if (advertiser$ | async; as advertiser) {
        @if (advertiser.baseIdEncrypted || advertiser.mundoBaseIdEncrypted) {
            <app-card-item>
                <app-card-item-title-text>
                    Content Engagement Reports
                </app-card-item-title-text>
                <app-card-item-title-description>
                    <div class="report-option-description">
                        Measure <span class="no-break">cross-channel</span> content performance.
                    </div>
                </app-card-item-title-description>
                @if (advertiser.baseIdEncrypted) {
                    <app-card-item-link>
                        <a mat-flat-button color="primary" class="pmg-primary-dark" style="width: 100%;"
                           [href]="'https://engagement.pmmimediagroup.com/' + advertiser.baseIdEncrypted" target="_blank">
                            VIEW REPORTS <mat-icon>open_in_new</mat-icon>
                        </a>
                    </app-card-item-link>
                }
                @if (advertiser.mundoBaseIdEncrypted) {
                    <app-card-item-link>
                        <a mat-flat-button color="primary" class="pmg-primary-dark" style="width: 100%;"
                           [href]="'https://engagement.mundoexpopack.com/' + advertiser.mundoBaseIdEncrypted" target="_blank">
                            VIEW MUNDO REPORTS <mat-icon>open_in_new</mat-icon>
                        </a>
                    </app-card-item-link>
                }
            </app-card-item>
        }

        <app-card-item>
            <app-card-item-title-text>
                Website Tracking (Scout) <span class="no-break">& Converge</span>
            </app-card-item-title-text>
            <app-card-item-title-description>
                <div class="report-option-description">
                    Connect <span class="no-break">end user</span> engagement with your advertising content and visits to your website.<br/>
                    View <span class="no-break">cross-channel</span> engagement from multiple contacts across an organization.
                 </div>
            </app-card-item-title-description>
            <app-card-item-link>
                <a mat-flat-button color="primary" class="pmg-primary-dark" style="width: 100%;"
                   [href]="convergeUrl + '/advertiser/' + advertiser.aid" target="_blank">
                    VIEW REPORTS <mat-icon>open_in_new</mat-icon>
                </a>
            </app-card-item-link>
        </app-card-item>

        @if (advertiser.mediaDiscoveryQuotes?.length) {
            <app-card-item>
                <app-card-item-title-text>
                    Media Discovery Options
                </app-card-item-title-text>
                <app-card-item-title-description>
                    <div class="report-option-description">
                        View media proposals built by your <span class="no-break">sales rep.</span>
                    </div>
                </app-card-item-title-description>
                <app-card-item-link>
                    <a mat-flat-button color="primary" class="pmg-primary-dark" style="width: 100%;" routerLink="media-discovery-tool">
                        VIEW OPTIONS
                    </a>
                </app-card-item-link>
            </app-card-item>
        }
        @if (advertiser.baseIdEncrypted || advertiser.mundoBaseIdEncrypted) {
            <app-card-item>
                <app-card-item-title-text>
                    Digital Leaders Profile
                </app-card-item-title-text>
                <app-card-item-title-description>
                    <div class="report-option-description">
                        Create or update your digital Leaders profile and data card displayed on our websites;
                        add or remove key contacts, featured products, social media channels and more.
                        If you're not participating in this year's Leaders programs and would like more information,
                        contact your <span class="no-break">Sales Representative.</span>
                    </div>
                </app-card-item-title-description>
                @if (advertiser.baseIdEncrypted && advertiser.enhancedAdvertiser) {
                    <app-card-item-link>
                        <a mat-flat-button color="primary" class="pmg-primary-dark" style="width: 100%;"
                           [href]="'https://companyupdate.pmmimediagroup.com/portal/' + advertiser.baseIdEncrypted" target="_blank">
                            VIEW PROFILE <mat-icon>open_in_new</mat-icon>
                        </a>
                    </app-card-item-link>
                }
                @if (advertiser.mundoBaseIdEncrypted && advertiser.enhancedAdvertiser) {
                    <app-card-item-link>
                        <a mat-flat-button color="primary" class="pmg-primary-dark" style="width: 100%;"
                           [href]="'https://companyupdate.mundoexpopack.com/portal/' + advertiser.mundoBaseIdEncrypted" target="_blank">
                            VIEW MUNDO PROFILE <mat-icon>open_in_new</mat-icon>
                        </a>
                    </app-card-item-link>
                }
            </app-card-item>
        }
    }
</app-card-list>
