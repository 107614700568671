import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';

import { IAdvertiser, IAdvertiserBasic } from 'model/advertiser';
import { environment } from 'util';

@Injectable({
    providedIn: 'root'
})
export class AdvertiserService {
    #apiUrl = environment.apiUrl;
    #apiGatewayUrl = environment.apiGatewayUrl;
    #cache: { [key: number]: IAdvertiser } = {};
    #basicCache: IAdvertiserBasic[] = [];

    constructor(
        private http: HttpClient
    ) {
    }

    getAdvertiser(aid: number): Observable<IAdvertiser> {
        if (this.#cache[aid]) {
            return of(this.#cache[aid]);
        }

        return this.http.get<IAdvertiser>(`${this.#apiUrl}/_/api/advertiser/${aid}`).pipe(
            map((advertiser: IAdvertiser) => {
                this.#cache[aid] = advertiser;
                return advertiser;
            })
        );
    }

    getAdvertiserList(): Observable<IAdvertiserBasic[]> {
        if (this.#basicCache.length) {
            return of(this.#basicCache);
        }

        return this.http.get<IAdvertiserBasic[]>(`${this.#apiUrl}/_/api/advertisers`).pipe(
            map((advertisers: IAdvertiserBasic[]) => {
                // sort by name
                return advertisers.sort((a, b) => a.name.localeCompare(b.name));
            }),
            map((advertisers: IAdvertiserBasic[]) => {
                this.#basicCache = advertisers;

                return advertisers;
            }),
        );
    }

    getAdvertisers(...aids: number[]): Observable<IAdvertiser[]> {
        const { cached, remaining } = this.findCachedAndRemainingAdvertisers(aids);

        const endpoint = `${this.#apiGatewayUrl}/advertisers?advertiserIds=${remaining.join(',')}`;

        return this.http.get<IAdvertiser[]>(endpoint, { withCredentials: true })
            .pipe(
                map((advertisers: IAdvertiser[]) => {
                    for (let advertiser of advertisers) {
                        this.#cache[advertiser.aid] = advertiser;
                    }

                    return cached.concat(advertisers);
                })
            );
    }

    protected findCachedAndRemainingAdvertisers(aids: number[]): { cached: IAdvertiser[], remaining: number[] } {
        let cached: IAdvertiser[] = [];
        let remaining: number[] = [];

        for (let aid of aids) {
            if (this.#cache[aid]) {
                cached.push(this.#cache[aid]);
            } else {
                remaining.push(aid);
            }
        }

        return { cached, remaining };
    }
}
