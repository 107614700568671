import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Observable, map, startWith, Subscription } from 'rxjs';

import { IAdvertiserBasic } from 'model';
import { AdvertiserService } from 'services';

@Component({
    selector: 'app-advertiser-autocomplete',
    standalone: true,
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatIconModule
    ],
    templateUrl: './advertiser-autocomplete.component.html',
    styleUrl: './advertiser-autocomplete.component.scss'
})
export class AdvertiserAutocompleteComponent implements OnInit, OnDestroy {
    protected form!: FormGroup;
    protected advertiserInput = new FormControl<IAdvertiserBasic | string>('');
    protected filteredAdvertisers!: Observable<IAdvertiserBasic[]>;
    #advertiserCache: IAdvertiserBasic[] = [];
    #subscriptions: Subscription[] = [];

    @Input() context: 'toolbar' | 'internalPage' = 'toolbar';
    @Output() advertiserSelected = new EventEmitter<IAdvertiserBasic | null>();
    @Input()
    set advertiserSubset(advertisers: IAdvertiserBasic[] | null) {
        if (!advertisers) {
            return;
        }
        this.setAdvertiserCache(advertisers);
    }

    constructor(
        private formBuilder: FormBuilder,
        private advertiserService: AdvertiserService,
        private router: Router
    ) {
        this.form = this.formBuilder.group({
            advertiserInput: this.advertiserInput
        });
    }

    ngOnInit(): void {
        this.fetchAdvertisers();
    }

    ngOnDestroy(): void {
        this.#subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    protected fetchAdvertisers(): void {
        if (this.context === 'internalPage') {
            return;
        }

        const subscription = this.advertiserService.getAdvertiserList()
            .subscribe((advertisers: IAdvertiserBasic[]) => {
                this.setAdvertiserCache(advertisers);
            });
        this.#subscriptions.push(subscription);
    }

    protected setAdvertiserCache(advertisers: IAdvertiserBasic[]): void {
        this.#advertiserCache = advertisers;
        this.filteredAdvertisers = this.advertiserInput.valueChanges.pipe(
            startWith(null),
            map((value: IAdvertiserBasic | string | null) => {
                if (typeof value === 'string' && !value) {
                    this.advertiserSelected.emit(null); // Emit null when input is cleared
                }
                return typeof value === 'string' ? value : value?.name || '';
            }),
            map(value => this.filterAdvertisers(value || ''))
        );
    }

    protected filterAdvertisers(value: string): IAdvertiserBasic[] {
        if (!value) {
            return this.#advertiserCache;
        }
        const filterValue = value.toLowerCase();
        return this.#advertiserCache.filter(advertiser => advertiser.name.toLowerCase().includes(filterValue));
    }

    protected displayFn(advertiser: IAdvertiserBasic): string {
        return advertiser && advertiser.name ? advertiser.name : '';
    }

    protected onOptionSelected(advertiser: IAdvertiserBasic): void {
        if (this.context === 'toolbar') {
            this.router.navigate(['/advertiser', advertiser.aid, 'upcoming-campaigns']).then(() => {
                this.advertiserInput.setValue('');
            });
        } else if (this.context === 'internalPage') {
            this.advertiserSelected.emit(advertiser);
        }
    }
}
