import { provideHttpClient, HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';

import { routes } from './app.routes';
import { AuthInterceptor, CredentialsInterceptor } from 'interceptors';
import { InMemoryDataService } from './services/in-memory-data.service';
import { environment } from 'util';

const localProviderSources: any[] = [];
const environmentalProviders: any = [];
if (environment.local) {
    localProviderSources.push(
        HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
            dataEncapsulation: false,
            delay: 2000
        }),
    );
}

environmentalProviders.push(
    provideServiceWorker('service-worker.js', {
        enabled: !environment.local,
        registrationStrategy: 'registerImmediately',
        scope: '/',
    }),
);

const providers = [
    provideRouter(routes, withComponentInputBinding(), withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom([
        ...localProviderSources
    ]),
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CredentialsInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
    {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
            subscriptSizing: 'dynamic',
        }
    },
    ...environmentalProviders
];

export const appConfig: ApplicationConfig = {
    providers: providers,
};
