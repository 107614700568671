<app-breadcrumb [breadcrumbs]="breadcrumbs$ | async"/>

<h1 class="page-title">Contacts Directory</h1>

<mat-divider class="page-title-divider"></mat-divider>

@if (!(advertiser$ | async)) {
    <div class="loader">
        <ngx-skeleton-loader count="8" appearance="line" [theme]="{height: '64px'}"></ngx-skeleton-loader>
    </div>
}

@if (advertiser$ | async; as advertiser) {
<mat-grid-list cols="3" rowHeight="1:1" gutterSize="32px">
    <mat-grid-tile>
        <mat-card class="mat-elevation-z2">
            <h3>Your PMG Contacts</h3>
            <mat-list>
                @if (advertiser.csm) {
                <mat-list-item>
                    <div matListItemTitle>Client Success Manager</div>
                    <div matListItemLine>
                        <mat-icon class="pmg-primary-dark">mail</mat-icon>
                        <a class="pmg-primary-dark" href="mailto:{{ (advertiser | asType: Advertiser).csmEmail }}">
                            {{ (advertiser.csm | asType : User).fullName }}
                        </a>
                    </div>
                </mat-list-item>
                }
                @for (salesRep of advertiser.salesReps; track salesRep.id) {
                <mat-list-item>
                    <div matListItemTitle>Sales Rep</div>
                    <div matListItemLine>
                        <mat-icon class="pmg-primary-dark">mail</mat-icon>
                        <a class="pmg-primary-dark" href="mailto:{{ (salesRep | asType: User).email }}">
                            {{ (salesRep | asType : User).fullName }}
                        </a>
                    </div>
                </mat-list-item>
                }
            </mat-list>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="mat-elevation-z2">
            <h3>Your Membership Contacts</h3>
            <mat-list>
                <mat-list-item>
                    <div matListItemTitle>Sr. Director of Membership</div>
                    <div matListItemLine>
                        <mat-icon class="pmg-primary-dark">mail</mat-icon>
                        <a class="pmg-primary-dark" href="mailto:adougherty@pmmi.org">
                            Andrew Dougherty
                        </a>
                    </div>
                </mat-list-item>
                <mat-list-item>
                    <div matListItemTitle>Membership Assistant</div>
                    <div matListItemLine>
                        <mat-icon class="pmg-primary-dark">mail</mat-icon>
                        <a class="pmg-primary-dark" href="mailto:lfinkelstein@pmmi.org">
                            Lindsay Finkelstein
                        </a>
                    </div>
                </mat-list-item>
            </mat-list>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="mat-elevation-z2">
            <h3>ProSource Directory Profile</h3>
            <p>
                A directory listing on <a class="pmg-primary-dark" href="https://www.prosource.org/">PMMI ProSource</a>
                is a free member benefit.  Questions about ProSource or trouble logging in?  Contact:
                <a class="pmg-primary-dark" href="mailto:prosource@pmmi.org">prosource&#64;pmmi.org</a>
            </p>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="mat-elevation-z2">
            <h3>Press Releases</h3>
            <mat-list>
                <mat-list-item>
                    <div matListItemTitle>Press Release Submission</div>
                    <div matListItemLine>
                        <mat-icon class="pmg-primary-dark">mail</mat-icon>
                        <a class="pmg-primary-dark" href="mailto:pressrelease@pmmimediagroup.com">
                            Press Release Contact
                        </a>
                    </div>
                </mat-list-item>
            </mat-list>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="mat-elevation-z2">
            <h3>PACK EXPO Show Contacts</h3>
            <mat-list>
                <mat-list-item>
                    <div matListItemTitle>Placeholder Title</div>
                    <div matListItemLine>
                        <mat-icon class="pmg-primary-dark">mail</mat-icon>
                        <a class="pmg-primary-dark" href="mailto:">
                            Placeholder Name
                        </a>
                    </div>
                </mat-list-item>
            </mat-list>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>
}
