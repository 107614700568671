import { Record } from 'immutable';
import { IAdvertiser, Advertiser } from './advertiser';

export type Role = 'admin' | 'advertiser' | 'salesRep' | 'production' | 'bom' | 'clientAccountManager';

export interface IUser {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: Role[];
    csm: IUser | null;
    advertisers: IAdvertiser[];
}
const userDefaultValues: IUser = {
    id: 0,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    csm: null,
    advertisers: []
};
const UserRecord = Record<IUser>({
    ...userDefaultValues
});
export class User extends UserRecord implements IUser {
    constructor(props: IUser) {
        if (props.csm) {
            props.csm = new User(props.csm);
        }
        props.advertisers = props.advertisers?.map(advertiser => new Advertiser(advertiser));
        super(props);
    }

    get csmEmail(): string {
        return this.csm?.email || '';
    }

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    get sortedAdvertisers(): IAdvertiser[] {
        return this.advertisers.sort((a, b) => a.name.localeCompare(b.name));
    }

    #isMultiAdvertiserUser!: boolean;
    get isMultiAdvertiserUser(): boolean {
        if (this.#isMultiAdvertiserUser === undefined) {
            this.#isMultiAdvertiserUser = this.advertisers.length > 1;
        }
        return this.#isMultiAdvertiserUser;
    }

    #isSalesRep!: boolean;
    get isSalesRep(): boolean {
        if (this.#isSalesRep === undefined) {
            this.#isSalesRep = this.roles.includes('salesRep');
        }
        return this.#isSalesRep;
    }

    #isAdvertiser!: boolean;
    get isAdvertiser(): boolean {
        if (this.#isAdvertiser === undefined) {
            this.#isAdvertiser = this.roles.includes('advertiser') && this.roles.length === 1;
        }
        return this.#isAdvertiser;
    }

    hasAdvertiser(): boolean {
        return this.advertisers.length > 0;
    }

    hasRole(roleName: Role): boolean {
        return this.roles.includes(roleName as Role);
    }

    isInternal(): boolean {
        return this.hasRole('admin')
            || this.hasRole('production')
            || this.hasRole('bom')
            || this.hasRole('salesRep')
            || this.hasRole('clientAccountManager');
    }
}
