import { CommonModule } from '@angular/common';
import { Component, ViewChild, Renderer2, HostListener, Input, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable, map, combineLatest, BehaviorSubject, ReplaySubject, mergeMap } from 'rxjs';

import { BreadcrumbComponent, Breadcrumb } from 'components/breadcrumb/breadcrumb.component';
import { IAdvertiser } from 'model';
import { AdvertiserService, CampaignService } from 'services';
import { SafePipe, environment } from 'util';

export enum CampaignPageType {
    Edit = 'edit',
    Leads = 'leads',
    Confirmation = 'confirmation',
    FacilitiesAndInfrastructure = 'facilities-and-infrastructure',
}

type RouteData = {
    isPastCampaign: boolean;
    campaignPageType: CampaignPageType;
    forceLeadView?: boolean;
};

@Component({
    selector: 'app-campaign',
    standalone: true,
    imports: [
        CommonModule,
        BreadcrumbComponent,
        SafePipe,
        NgxSkeletonLoaderModule,
    ],
    templateUrl: './campaign.component.html',
    styleUrl: './campaign.component.scss'
})
export class CampaignComponent implements AfterViewInit {
    private apiUrl = environment.apiUrl;

    protected breadcrumbs$!: Observable<Breadcrumb[]>;

    private advertiserIdSubject: ReplaySubject<number> = new ReplaySubject<number>(1);
    private campaignIdSubject: ReplaySubject<number> = new ReplaySubject<number>(1);
    private eventIdSubject: ReplaySubject<number> = new ReplaySubject<number>(1);

    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    protected loading$: Observable<boolean> = this.loadingSubject.asObservable();

    @ViewChild('campaignIframe') campaignIframe!: ElementRef<HTMLIFrameElement>;

    @ViewChild('contentWrapper') contentWrapper!: ElementRef<HTMLDivElement>;

    @Input()
    set advertiserId(advertiserId: number) {
        this.advertiserIdSubject.next(advertiserId);
        this.breadcrumbs$ = combineLatest([
            this.advertiserService.getAdvertiser(advertiserId),
            this.route.data
        ]).pipe(
            map(([advertiser, data]: [IAdvertiser, RouteData | Data]) => {
                const indexPage = (data as RouteData).isPastCampaign
                    ? { label: 'Past Campaigns', url: `/advertiser/${advertiser.aid}/past-campaigns` }
                    : { label: 'Upcoming Campaigns', url: `/advertiser/${advertiser.aid}/upcoming-campaigns` };
                return [
                    { label: advertiser.name, url: `/advertiser/${advertiser.aid}/upcoming-campaigns` },
                    indexPage,
                    { label: 'Campaign', url: '' },
                ];
            })
        );
    }

    @Input()
    set campaignId(campaignId: number) {
        this.campaignIdSubject.next(campaignId);
    }

    @Input()
    set eventId(eventId: number) {
        this.eventIdSubject.next(eventId);
    }

    // add listener for browser resize event
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.campaignIframe?.nativeElement.contentWindow?.postMessage({type: 'iframeResize'}, '*');
    }

    constructor(
        private renderer: Renderer2,
        private advertiserService: AdvertiserService,
        private campaignService: CampaignService,
        private route: ActivatedRoute
    ) {
        window.addEventListener('message', (event) => {
            if (event.data.type === 'iframeHeight') {
                this.renderer.setStyle(this.campaignIframe.nativeElement, 'height', event.data.height + 80 + 'px');
            }
        });

        combineLatest([
            this.advertiserIdSubject,
            this.campaignIdSubject,
            this.eventIdSubject,
            this.route.data
        ]).pipe(
            mergeMap(([advertiserId, campaignId, eventId, data]: [number, number, number, RouteData | Data]) => {
                const campaignPageType = (data as RouteData).campaignPageType;
                const forceLeadView = (data as RouteData).forceLeadView || false;
                switch (campaignPageType) {
                    case CampaignPageType.Edit:
                    default:
                        return this.campaignService.getCampaignEditPage(campaignId);
                    case CampaignPageType.Leads:
                        return this.campaignService.getCampaignLeads(campaignId, forceLeadView);
                    case CampaignPageType.Confirmation:
                        return this.campaignService.getCampaignConfirmationPage(campaignId);
                    case CampaignPageType.FacilitiesAndInfrastructure:
                        return this.campaignService.getCampaignFacilitiesAndInfrastructurePage(advertiserId, eventId);
                }
            })
        ).subscribe((content: string) => {
            this.loadingSubject.next(false);
            this.loadContent(content);
        });
    }

    ngAfterViewInit() {
        /*this.campaignIframe.nativeElement.onload = () => {
            this.loadingSubject.next(false);
        }*/
    }

    loadContent(content: string) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');

        const contentElement: HTMLDivElement = this.renderer.createElement('div');
        contentElement.id = 'campaign-content';

        const loadScript = (script: HTMLScriptElement, parent: Node) => {
            const scriptElement: HTMLScriptElement = this.renderer.createElement('script');
            if (script.src) {
                this.renderer.setAttribute(scriptElement, 'src', this.stripBaseUrl(script.src));
            }
            if (script.textContent) {
                scriptElement.text = script.textContent;
            }
            this.renderer.setAttribute(scriptElement, 'type', 'text/javascript');
            this.renderer.appendChild(parent, scriptElement);
        }

        const loadCssStylesheet = (link: HTMLLinkElement, parent: Node) => {
            const styleElement = this.renderer.createElement('link');
            styleElement.rel = 'stylesheet';
            styleElement.href = this.stripBaseUrl(link.href);
            this.renderer.appendChild(parent, styleElement);
        }

        const loadStyle = (style: HTMLStyleElement, parent: Node) => {
            const styleElement = this.renderer.createElement('style');
            styleElement.innerHTML = style.innerHTML;
            this.renderer.appendChild(parent, styleElement);
        }

        // Add css
        const nodes: NodeListOf<HTMLLinkElement> = doc.head.querySelectorAll('link[rel="stylesheet"], style, script');
        nodes.forEach((node: HTMLElement) => {
            if (node.nodeName === 'LINK') {
                loadCssStylesheet(node as HTMLLinkElement, contentElement);
            } else if (node.nodeName === 'STYLE') {
                loadStyle(node as HTMLStyleElement, contentElement);
            } else if (node.nodeName === 'SCRIPT') {
                loadScript(node as HTMLScriptElement, contentElement);
            }
        });

        // Inject body content
        const bodyFragment = doc.createDocumentFragment();
        Array.from(doc.body.childNodes).forEach((node: Node) => {
            if (node.nodeName === 'SCRIPT') {
                loadScript(node as HTMLScriptElement, bodyFragment);
            } else if (node.nodeName === 'STYLE') {
                loadStyle(node as HTMLStyleElement, bodyFragment);
            } else {
                bodyFragment.appendChild(node);
            }
        });
        this.renderer.appendChild(contentElement, bodyFragment);

        this.renderer.appendChild(this.contentWrapper.nativeElement, contentElement);
    }

    protected stripBaseUrl(url: string): string {
        const baseUrl = window.location.origin;
        return url.replace(baseUrl, this.apiUrl);
    }
}
