import { Injectable } from '@angular/core';
import { InMemoryDbService, RequestInfo, RequestInfoUtilities, ResponseOptions } from 'angular-in-memory-web-api';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { IUser, Role } from 'model/user';
import { IAdvertiser, ICampaign, ITradeShow } from 'model';
import { TradeShowReportsResponse } from './campaign.service';

@Injectable({
    providedIn: 'root'
})
export class InMemoryDataService implements InMemoryDbService {
    protected upcomingCampaigns: ICampaign[] = [
        {
            cid: 1, aid: 1, date: 1701410400,
            name: 'Packaging Insights', brandId: 3, type: 1, materialsDue: 1722488400,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: false, templateId: 455,
            url: 'https://pmg-production.s3.amazonaws.com/NL-previews/PWNL2022/PW%20End-of-Line%20Strategies%20-%20BASE-080522.html',
        },
        {
            cid: 2, aid: 1, date: 1704175200,
            name: 'Packaging World Video eBlast', brandId: 1, type: 1, materialsDue: 1722199325,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: false, templateId: 455,
            url: 'https://pmg-production.s3.amazonaws.com/NL-previews/PWNL2022/PW%20End-of-Line%20Strategies%20-%20BASE-080522.html',
        },
        {
            cid: 3, aid: 1, date: 1733896800,
            name: 'Packaging World Video eBlast', brandId: 1, type: 1, materialsDue: 1727758800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: false, templateId: 455,
            url: 'https://pmg-production.s3.amazonaws.com/NL-previews/PWNL2022/PW%20End-of-Line%20Strategies%20-%20BASE-080522.html',
        },
        {
            cid: 4, aid: 1, date: 1729227600,
            name: 'Packaging World Magazine - 1/2 Horizontal', brandId: 1, type: 9, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            projectUrl: 'https://www.example.com', url: null,
        },
        {
            cid: 5, aid: 1, date: 1712034000,
            name: 'Packaging World Video eBlast',
            brandId: 1, type: 1, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            url: 'https://pmg-production.s3.amazonaws.com/NL-previews/PWNL2022/PW%20End-of-Line%20Strategies%20-%20BASE-080522.html',
        },
        {
            cid: 6, aid: 1, date: 1710824400,
            name: 'Packaging World Video eBlast', brandId: 1, type: 1, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            url: 'https://pmg-production.s3.amazonaws.com/NL-previews/PWNL2022/PW%20End-of-Line%20Strategies%20-%20BASE-080522.html',
        },
        {
            cid: 7, aid: 1, date: 1719118800,
            name: 'Packaging World Video eBlast', brandId: 1, type: 1, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            url: 'https://pmg-production.s3.amazonaws.com/NL-previews/PWNL2022/PW%20End-of-Line%20Strategies%20-%20BASE-080522.html',
        },
    ];

    protected pastCampaigns: ICampaign[] = [
        // Email
        {
            cid: 8, aid: 1, date: 1701410400, leadCount: 65,
            name: 'Packaging Insights', brandId: 3, type: 1, materialsDue: 1722488400,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            url: 'https://pmg-production.s3.amazonaws.com/NL-previews/PWNL2022/PW%20End-of-Line%20Strategies%20-%20BASE-080522.html',
        },
        {
            cid: 9, aid: 1, date: 1685595600, leadCount: 57,
            name: 'Packaging World Video eBlast', brandId: 1, type: 1, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            url: 'https://pmg-production.s3.amazonaws.com/NL-previews/PWNL2022/PW%20End-of-Line%20Strategies%20-%20BASE-080522.html',
        },
        {
            cid: 10, aid: 1, date: 1685941200, leadCount: 93,
            name: 'Packaging World Video eBlast', brandId: 1, type: 1, materialsDue: 1727758800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            url: 'https://pmg-production.s3.amazonaws.com/NL-previews/PWNL2022/PW%20End-of-Line%20Strategies%20-%20BASE-080522.html',
        },

        // Social Media
        {
            cid: 11, aid: 1, date: 1685595600, uniqueClicks: 568, uniqueClickThroughRate: 0.1245,
            name: 'Packaging World Facebook', brandId: 1, type: 7, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, url: null,
            audienceNetworkStartDate: 1643735209, audienceNetworkStopDate: 1672506409, audienceNetworkCampaignStatus: 'Complete',
        },
        {
            cid: 12, aid: 1, date: 1701410400, uniqueClicks: 340, uniqueClickThroughRate: 0.0356,
            name: 'Packaging World Facebook 2',
            brandId: 1, type: 7, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, url: null,
            audienceNetworkStartDate: 1643735209, audienceNetworkStopDate: 1672506409, audienceNetworkCampaignStatus: 'Complete',
        },
        {
            cid: 13, aid: 1, date: 1688533200,
            name: 'Packaging World LinkedIn', brandId: 1, type: 8, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, url: null,
            audienceNetworkStartDate: 1643735209, audienceNetworkStopDate: 1672506409, audienceNetworkCampaignStatus: 'Complete',
        },
        {
            cid: 14, aid: 1, date: 1688965200,
            name: 'Packaging World LinkedIn 2', brandId: 1, type: 8, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, url: null,
            audienceNetworkStartDate: 1643735209, audienceNetworkStopDate: 1672506409, audienceNetworkCampaignStatus: 'Complete',
        },

        // (Web) Targeted Branding and Video Exposure
        {
            cid: 15, aid: 1, date: 1685595600,
            name: 'Packaging World Targeted Branding', brandId: 1, type: 5, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: 30, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, impressions: 49635, url: null,
            audienceNetworkStartDate:1643735209, audienceNetworkStopDate:1672506409, audienceNetworkCampaignStatus: 'Complete',
        },
        {
            cid: 16, aid: 1, date: 1685595600,
            name: 'Packaging World Video Exposure', brandId: 1, type: 6, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: 54, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, impressions: 7536, url: null,
            audienceNetworkStartDate:1643735209, audienceNetworkStopDate:1672506409, audienceNetworkCampaignStatus: 'Complete',
        },

        // Ebooks
        {
            cid: 17, aid: 1, date: 1685595600, leadCount: 132,
            name: 'Robotics Playbook', brandId: 1, type: 2, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: 1, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, impressions: 49635, url: null,
        },
        {
            cid: 18, aid: 1, date: 1685595600, leadCount: 128,
            name: 'Robotics Playbook 2', brandId: 1, type: 2, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: 1, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, impressions: 7536, url: null,
        },

        // Webinar
        {
            cid: 19, aid: 1, date: 1685595600, leadCount: 128,
            name: 'Webinar', brandId: 1, type: 2, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: 18, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, impressions: 7536, url: null,
        },

        // Print
        {
            cid: 20, aid: 1, date: 1685595600, leadCount: 128,
            name: 'Print 1', brandId: 1, type: 9, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, impressions: 7536, url: null,
        },
        {
            cid: 21, aid: 1, date: 1685595600, leadCount: 128,
            name: 'Print 2', brandId: 1, type: 9, materialsDue: 1725166800,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, impressions: 7536, url: null,
        },

        // Booth Scan & Online Showroom
        {
            cid: 22, aid: 1, date: 1685595600, leadCount: 128,
            name: 'Booth Scan', brandId: 1, type: 4, materialsDue: null,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, impressions: 7536, tradeShowId: 13, url: null,
            tradeShow: {
                active: false,
                exhibitorPortalUrl: null,
                fullName: "PACK EXPO International 2022",
                gamePlanDate: 1666196372,
                id: 13,
                name: "PEI 2022",
                secondLookDate: 1667841572,
                showType: "pei",
                year: 2022,
            }
        },
        {
            cid: 23, aid: 1, date: 1685595600, leadCount: 128,
            name: 'Online Showroom', brandId: 1, type: 4, materialsDue: null,
            assignedTo: null, createdBy: null, expireDate: null, subtype: null, approved: true, templateId: 455,
            reportingCompleteDate: 1695186000, impressions: 7536, tradeShowId: 14, url: null,
            tradeShow: {
                active: false,
                exhibitorPortalUrl: null,
                fullName: "PACK EXPO Las Vegas 2023",
                gamePlanDate: 1694535572,
                id: 14,
                name: "PELV 2023",
                secondLookDate: 1695745172,
                showType: "pelv",
                year: 2023
            }
        },
    ];

    // add 'active' => false to each entry of the below array
    protected tradeShows: ITradeShow[] = [
        {
            active: false,
            exhibitorPortalUrl: null,
            fullName: "PACK EXPO Las Vegas 2015",
            gamePlanDate: 1442420372,
            id: 4,
            name: "PELV 2015",
            secondLookDate: 1444839572,
            showType: "pelv",
            year: 2015
        }, {
            id: 3,
            name: "PEI 2016",
            fullName: "PACK EXPO International 2016",
            year: 2016,
            showType: "pei",
            secondLookDate: 1479316772,
            gamePlanDate: 1476289172,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 1,
            name: "PELV 2017",
            fullName: "PACK EXPO Las Vegas 2017",
            year: 2017,
            showType: "pelv",
            secondLookDate: 1507738772,
            gamePlanDate: 1505319572,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 5,
            name: "PEI 2018",
            fullName: "PACK EXPO International 2018",
            year: 2018,
            showType: "pei",
            secondLookDate: 1540397972,
            gamePlanDate: 1537460372,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 2,
            name: "PXE 2018",
            fullName: "PACK EXPO East 2018",
            year: 2018,
            showType: "pxe",
            secondLookDate: null,
            gamePlanDate: null,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 7,
            name: "PELV 2019",
            fullName: "PACK EXPO Las Vegas 2019",
            year: 2019,
            showType: "pelv",
            secondLookDate: 1571156372,
            gamePlanDate: 1568564372,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 6,
            name: "PFT 2019",
            fullName: "Pro Food Tech 2019",
            year: 2019,
            showType: "pft",
            secondLookDate: null,
            gamePlanDate: null,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 9,
            name: "PEC 2020",
            fullName: "PACK EXPO Connects 2020",
            year: 2020,
            showType: "pec",
            secondLookDate: 1606065572,
            gamePlanDate: null,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 8,
            name: "PXE 2020",
            fullName: "PACK EXPO East 2020",
            year: 2020,
            showType: "pxe",
            secondLookDate: null,
            gamePlanDate: null,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 10,
            name: "PELV 2021",
            fullName: "PACK EXPO Las Vegas 2021",
            year: 2021,
            showType: "pelv",
            secondLookDate: 1634314772,
            gamePlanDate: 1632154772,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 11,
            name: "PXE 2022",
            fullName: "PACK EXPO East 2022",
            year: 2022,
            showType: "pxe",
            secondLookDate: null,
            gamePlanDate: null,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 12,
            name: "EPM 2022",
            fullName: "EXPO PACK Mexico 2022",
            year: 2022,
            showType: "pem",
            secondLookDate: null,
            gamePlanDate: null,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 13,
            name: "PEI 2022",
            fullName: "PACK EXPO International 2022",
            year: 2022,
            showType: "pei",
            secondLookDate: 1667841572,
            gamePlanDate: 1666196372,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 14,
            name: "PELV 2023",
            fullName: "PACK EXPO Las Vegas 2023",
            year: 2023,
            showType: "pelv",
            secondLookDate: 1695745172,
            gamePlanDate: 1694535572,
            exhibitorPortalUrl: null,
            active: false
        }, {
            id: 15,
            name: "PXE 2024",
            fullName: "PACK EXPO East 2024",
            year: 2024,
            active: true,
            showType: "pxe",
            secondLookDate: null,
            gamePlanDate: null,
            exhibitorPortalUrl: "https://packeast24.exh.mapyourshow.com/6_0/login.cfm"
        }, {
            id: 16,
            name: "EPM 2024",
            fullName: "Expo Pack (Mexico) 2024",
            year: 2024,
            active: true,
            showType: "pem",
            secondLookDate: null,
            gamePlanDate: null,
            exhibitorPortalUrl: "https://epm2024.exh.mapyourshow.com/6_0/login.cfm",
        }];

    protected tradeShowCampaigns: TradeShowReportsResponse = {
        advertiser: {
            aid: 1,
            name: 'Packaging World',
            brands: [1],
            baseIdEncrypted: 'eeced8a04259538132d66ba981b95605',
            mundoBaseIdEncrypted: 'b4d3eb51f271a41e2087984a00498372',
            salesForceId: '0011I000010tzMGQAY',
            mediaDiscoveryQuotes: [
                {
                    id: 1,
                    name: "3D Barrier Bags - 2024 Media Opps",
                    createdDate: 1699384291
                },
                {
                    id: 2,
                    name: "3D Barrier Bags - 2024 Media 1",
                    createdDate: 1700500266
                },
                {
                    id: 3,
                    name: "3D Barrier Bags - 2024 Media 2",
                    createdDate: 1700500307
                }
            ],
            csm: {
                id: 10,
                username: 'csm_user',
                firstName: 'CSM',
                lastName: 'User',
                email: 'csm@company.com',
                roles: [],
                csm: null,
                advertisers: [],
            },
            salesReps: [
                {
                    id: 11,
                    username: 'sales_rep',
                    firstName: 'Sales',
                    lastName: 'Rep 1',
                    email: 'sales-rep-1@company.com',
                    roles: [],
                    csm: null,
                    advertisers: [],
                },
                {
                    id: 12,
                    username: 'sales_rep_2',
                    firstName: 'Sales',
                    lastName: 'Rep 2',
                    email: 'sales-rep-2@company.com',
                    roles: [],
                    csm: null,
                    advertisers: [],
                }
            ]
        },
        gamePlan: [
            {
                aid: 1,
                eventId: 14,
                name: 'Game Plan PACK EXPO Las Vegas 2023',
                categoryName: 'Food Processing Equipment',
                recipients: 4129,
                publishDate: 1694494800,
                tradeShow: {
                    id: 14,
                    name: 'PELV 2023',
                    fullName: 'PACK EXPO Las Vegas 2023',
                    year: 2023,
                    showType: 'pelv',
                    secondLookDate: 1695704400,
                    gamePlanDate: 1694494800,
                    exhibitorPortalUrl: null,
                    active: false
                }
            },
            {
                aid: 1,
                eventId: 13,
                name: 'Game Plan PACK EXPO International 2022',
                categoryName: 'Food Processing Equipment',
                recipients: 6122,
                publishDate: 1476300709,
                tradeShow: {
                    id: 13,
                    name: 'PEI 2022',
                    fullName: 'PACK EXPO International 2022',
                    year: 2022,
                    showType: 'pei',
                    secondLookDate: 1667800800,
                    gamePlanDate: 1666155600,
                    exhibitorPortalUrl: null,
                    active: false,
                }
            }
        ],
        secondLook: [
            {
                aid: 1,
                eventId: 14,
                name: 'PACK EXPO Las Vegas 2023 Second Look',
                categoryName: 'Form/fill/seal - horizontal',
                recipients: 4129,
                publishDate: 1695704400,
                tradeShow: {
                    id: 14,
                    name: 'PELV 2023',
                    fullName: 'PACK EXPO Las Vegas 2023',
                    year: 2023,
                    showType: 'pelv',
                    secondLookDate: 1695704400,
                    gamePlanDate: 1694494800,
                    exhibitorPortalUrl: null,
                    active: false,
                }
            },
            {
                aid: 1,
                eventId: 13,
                name: 'Second Look PACK EXPO International 2022',
                categoryName: 'Cartoning',
                recipients: 6122,
                publishDate: 1667800800,
                tradeShow: {
                    id: 13,
                    name: 'PEI 2022',
                    fullName: 'PACK EXPO International 2022',
                    year: 2022,
                    showType: 'pei',
                    secondLookDate: 1667800800,
                    gamePlanDate: 1666155600,
                    exhibitorPortalUrl: null,
                    active: false,
                }
            }
        ],
        facilitiesAndInfrastructure: [
            {
                aid: 1,
                eventId: 14,
                name: 'Facilities & Infrastructure Directory Report 2023',
                publishDate: 1694494800,
                tradeShow: {
                    id: 14,
                    name: 'PELV 2023',
                    fullName: 'PACK EXPO Las Vegas 2023',
                    year: 2023,
                    showType: 'pelv',
                    secondLookDate: 1695704400,
                    gamePlanDate: 1694494800,
                    exhibitorPortalUrl: null,
                    active: false,
                }
            },
            {
                aid: 1,
                eventId: 13,
                name: 'Facilities & Infrastructure Directory Report 2022',
                publishDate: 1666155600,
                tradeShow: {
                    id: 13,
                    name: 'PEI 2022',
                    fullName: 'PACK EXPO International 2022',
                    year: 2022,
                    showType: 'pei',
                    secondLookDate: 1667800800,
                    gamePlanDate: 1666155600,
                    exhibitorPortalUrl: null,
                    active: false,
                }
            }
        ],
    };

    protected advertiser: IAdvertiser = {
        aid: 1,
        name: 'Packaging World',
        brands: [1],
        baseIdEncrypted: 'eeced8a04259538132d66ba981b95605',
        mundoBaseIdEncrypted: 'b4d3eb51f271a41e2087984a00498372',
        salesForceId: '0011I000010tzMGQAY',
        mediaDiscoveryQuotes: [
            {
                id: 1,
                name: "3D Barrier Bags - 2024 Media Opps",
                createdDate: 1699384291
            },
            {
                id: 2,
                name: "3D Barrier Bags - 2024 Media 1",
                createdDate: 1700500266
            },
            {
                id: 3,
                name: "3D Barrier Bags - 2024 Media 2",
                createdDate: 1700500307
            }
        ],
        csm: {
            id: 10,
            username: 'csm_user',
            firstName: 'CSM',
            lastName: 'User',
            email: 'csm@company.com',
            roles: [],
            csm: null,
            advertisers: [],
        },
        salesReps: [
            {
                id: 11,
                username: 'sales_rep',
                firstName: 'Sales',
                lastName: 'Rep 1',
                email: 'sales-rep-1@company.com',
                roles: [],
                csm: null,
                advertisers: [],
            },
            {
                id: 12,
                username: 'sales_rep_2',
                firstName: 'Sales',
                lastName: 'Rep 2',
                email: 'sales-rep-2@company.com',
                roles: [],
                csm: null,
                advertisers: [],
            }
        ]
    }

    protected advertisers: IAdvertiser[] = [
        {
            aid: 1,
            name: 'Packaging World',
            brands: [1],
            baseIdEncrypted: 'eeced8a04259538132d66ba981b95605',
            mundoBaseIdEncrypted: 'b4d3eb51f271a41e2087984a00498372',
            salesForceId: '0011I000010tzMGQAY',
            mediaDiscoveryQuotes: [
                {
                    id: 1,
                    name: "3D Barrier Bags - 2024 Media Opps",
                    createdDate: 1699384291
                },
                {
                    id: 2,
                    name: "3D Barrier Bags - 2024 Media 1",
                    createdDate: 1700500266
                },
                {
                    id: 3,
                    name: "3D Barrier Bags - 2024 Media 2",
                    createdDate: 1700500307
                }
            ],
            csm: {
                id: 10,
                username: 'csm_user',
                firstName: 'CSM',
                lastName: 'User',
                email: 'csm@company.com',
                roles: [],
                csm: null,
                advertisers: [],
            },
            salesReps: [
                {
                    id: 11,
                    username: 'sales_rep',
                    firstName: 'Sales',
                    lastName: 'Rep 1',
                    email: 'sales-rep-1@company.com',
                    roles: [],
                    csm: null,
                    advertisers: [],
                },
                {
                    id: 12,
                    username: 'sales_rep_2',
                    firstName: 'Sales',
                    lastName: 'Rep 2',
                    email: 'sales-rep-2@company.com',
                    roles: [],
                    csm: null,
                    advertisers: [],
                }
            ]
        },
        {
            aid: 2,
            name: 'ABC Company',
            brands: [1],
            baseIdEncrypted: 'eeced8a04259538132d66ba981b95605',
            mundoBaseIdEncrypted: 'b4d3eb51f271a41e2087984a00498372',
            salesForceId: '0011I000010tzMGQAY',
            mediaDiscoveryQuotes: [
                {
                    id: 1,
                    name: "3D Barrier Bags - 2024 Media Opps",
                    createdDate: 1699384291
                },
                {
                    id: 2,
                    name: "3D Barrier Bags - 2024 Media 1",
                    createdDate: 1700500266
                },
                {
                    id: 3,
                    name: "3D Barrier Bags - 2024 Media 2",
                    createdDate: 1700500307
                }
            ],
            csm: {
                id: 10,
                username: 'csm_user',
                firstName: 'CSM',
                lastName: 'User',
                email: 'csm@company.com',
                roles: [],
                csm: null,
                advertisers: [],
            },
            salesReps: [
                {
                    id: 11,
                    username: 'sales_rep',
                    firstName: 'Sales',
                    lastName: 'Rep 1',
                    email: 'sales-rep-1@company.com',
                    roles: [],
                    csm: null,
                    advertisers: [],
                },
                {
                    id: 12,
                    username: 'sales_rep_2',
                    firstName: 'Sales',
                    lastName: 'Rep 2',
                    email: 'sales-rep-2@company.com',
                    roles: [],
                    csm: null,
                    advertisers: [],
                }
            ]
        },
        {
            aid: 3,
            name: 'Initech',
            brands: [1, 3],
            baseIdEncrypted: 'eeced8a04259538132d66ba981b95605',
            mundoBaseIdEncrypted: 'b4d3eb51f271a41e2087984a00498372',
            salesForceId: '0011I000010tzMGQAY',
            mediaDiscoveryQuotes: [
                {
                    id: 1,
                    name: "3D Barrier Bags - 2024 Media Opps",
                    createdDate: 1699384291
                },
                {
                    id: 2,
                    name: "3D Barrier Bags - 2024 Media 1",
                    createdDate: 1700500266
                },
                {
                    id: 3,
                    name: "3D Barrier Bags - 2024 Media 2",
                    createdDate: 1700500307
                }
            ],
            csm: {
                id: 10,
                username: 'csm_user',
                firstName: 'CSM',
                lastName: 'User',
                email: 'csm@company.com',
                roles: [],
                csm: null,
                advertisers: [],
            },
            salesReps: [
                {
                    id: 11,
                    username: 'sales_rep',
                    firstName: 'Sales',
                    lastName: 'Rep 1',
                    email: 'sales-rep-1@company.com',
                    roles: [],
                    csm: null,
                    advertisers: [],
                },
                {
                    id: 12,
                    username: 'sales_rep_2',
                    firstName: 'Sales',
                    lastName: 'Rep 2',
                    email: 'sales-rep-2@company.com',
                    roles: [],
                    csm: null,
                    advertisers: [],
                }
            ]
        }
    ];

    createDb() {
        const user: IUser = {
            id: 1,
            username: 'admin',
            firstName: 'Admin',
            lastName: 'User',
            email: 'guy@admin.com',
            roles: <Role[]>['admin'],
            csm: null,
            advertisers: [
                {
                    aid: 1,
                    name: 'Packaging World',
                    brands: [1, 3],
                    baseIdEncrypted: 'eeced8a04259538132d66ba981b95605',
                    mundoBaseIdEncrypted: 'b4d3eb51f271a41e2087984a00498372',
                    salesForceId: '0011I000010tzMGQAY',
                    mediaDiscoveryQuotes: [
                        {
                            id: 1,
                            name: "3D Barrier Bags - 2024 Media Opps",
                            createdDate: 1699384291
                        },
                        {
                            id: 2,
                            name: "3D Barrier Bags - 2024 Media 1",
                            createdDate: 1700500266
                        },
                        {
                            id: 3,
                            name: "3D Barrier Bags - 2024 Media 2",
                            createdDate: 1700500307
                        }
                    ]
                },
                {
                    aid: 3,
                    name: 'A-B-C Packaging Machinery Corp',
                    brands: [13],
                    baseIdEncrypted: null,
                    mundoBaseIdEncrypted: null,
                    salesForceId: '0011I000010tzMGQAY',
                }
            ],
        };

        const db = {
            user,
            upcomingCampaigns: this.upcomingCampaigns,
            pastCampaigns: this.pastCampaigns,
            tradeShowCampaigns: this.tradeShowCampaigns,
            advertiser: this.advertiser,
            tradeShows: this.tradeShows,
        };

        return of(db).pipe(delay(10));
    }

    get(reqInfo: RequestInfo) {
        const collectionName = reqInfo.collectionName;
        if (collectionName === 'upcomingCampaigns') {
            return this.getUpcomingCampaigns(reqInfo);
        }
        if (collectionName === 'pastCampaigns') {
            return this.getPastCampaigns(reqInfo);
        }
        if (collectionName === 'tradeShowCampaigns') {
            return this.getTradeShowCampaigns(reqInfo);
        }
        if (collectionName === 'advertiser') {
            return this.getAdvertiser(reqInfo);
        }
        if (collectionName === 'advertisers') {
            return this.getAdvertisers(reqInfo);
        }
        if (collectionName === 'tradeShows') {
            return this.getTradeShows(reqInfo);
        }
        if (collectionName === 'campaignLeads') {
            return this.getCampaignLeads(reqInfo);
        }
        if (collectionName === 'campaignEdit') {
            return this.getCampaignEdit(reqInfo);
        }
        if (collectionName === 'campaignConfirmation') {
            return this.getCampaignConfirmation(reqInfo);
        }
        return undefined;
    }

    getUpcomingCampaigns(reqInfo: RequestInfo) {
        return reqInfo.utils.createResponse$(() => {
            const aid = reqInfo.id !== undefined ? +reqInfo.id : undefined;
            const data: ICampaign[] = aid ? this.upcomingCampaigns.filter(c => c.aid === aid) : this.upcomingCampaigns;
            const options: ResponseOptions = data
                ? {body: data, status: 200}
                : {status: 404};
            return this.finishOptions(options, reqInfo);
        });
    }

    getPastCampaigns(reqInfo: RequestInfo) {
        return reqInfo.utils.createResponse$(() => {
            const aid = reqInfo.id !== undefined ? +reqInfo.id : undefined;
            const data: ICampaign[] = aid ? this.pastCampaigns.filter(c => c.aid === aid) : this.pastCampaigns;
            const options: ResponseOptions = data
                ? {body: data, status: 200}
                : {status: 404};
            return this.finishOptions(options, reqInfo);
        });
    }

    getTradeShowCampaigns(reqInfo: RequestInfo) {
        return reqInfo.utils.createResponse$(() => {
            const data: TradeShowReportsResponse = this.tradeShowCampaigns;
            const options: ResponseOptions = data
                ? {body: data, status: 200}
                : {status: 404};
            return this.finishOptions(options, reqInfo);
        });
    }

    getAdvertiser(reqInfo: RequestInfo) {
        return reqInfo.utils.createResponse$(() => {
            const data: IAdvertiser = this.advertiser;
            const options: ResponseOptions = data
                ? {body: data, status: 200}
                : {status: 404};
            return this.finishOptions(options, reqInfo);
        });
    }

    getAdvertisers(reqInfo: RequestInfo) {
        return reqInfo.utils.createResponse$(() => {
            const data = this.advertisers;
            const options: ResponseOptions = data
                ? {body: data, status: 200}
                : {status: 404};
            return this.finishOptions(options, reqInfo);
        });
    }

    getTradeShows(reqInfo: RequestInfo) {
        return reqInfo.utils.createResponse$(() => {
            const data = this.tradeShows;
            const options: ResponseOptions = data
                ? {body: data, status: 200}
                : {status: 404};
            return this.finishOptions(options, reqInfo);
        });
    }

    getCampaignLeads(reqInfo: RequestInfo) {
        return reqInfo.utils.createResponse$(() => {
            const data = this.campaignHtml;
            const options: ResponseOptions = data
                ? {body: data, status: 200}
                : {status: 404};
            return this.finishOptions(options, reqInfo);
        });
    }

    getCampaignEdit(reqInfo: RequestInfo) {
        return reqInfo.utils.createResponse$(() => {
            const data = this.campaignHtml;
            const options: ResponseOptions = data
                ? {body: data, status: 200}
                : {status: 404};
            return this.finishOptions(options, reqInfo);
        });
    }

    getCampaignConfirmation(reqInfo: RequestInfo) {
        return reqInfo.utils.createResponse$(() => {
            const data = this.campaignConfirmationHtml;
            const options: ResponseOptions = data
                ? {body: data, status: 200}
                : {status: 404};
            return this.finishOptions(options, reqInfo);
        });
    }

    parseRequestUrl(url: string, utils: RequestInfoUtilities) {
        const newUrl = url
            .replace(/_\/api\/campaign\/upcoming/, 'api/upcomingCampaigns')
            .replace(/_\/api\/campaign\/past-trade-show/, 'api/tradeShowCampaigns')
            .replace(/_\/api\/campaign\/past/, 'api/pastCampaigns')
            .replace(/_\/api\/campaign\/trade-shows/, 'api/tradeShows')
            .replace(/_\/api\/campaign\/leads/, 'api/campaignLeads')
            .replace(/_\/api\/campaign\/edit/, 'api/campaignEdit')
            .replace(/_\/api\/campaign\/confirmation/, 'api/campaignConfirmation')
            .replace(/_\/api\/advertisers\/?$/, 'api/advertisers')
            .replace(/_\/api\/advertiser/, 'api/advertiser')
            .replace(/_\/api\/user/, 'api/user');
        return utils.parseRequestUrl(newUrl);
    }

    protected finishOptions(options: ResponseOptions, {headers, url}: RequestInfo) {
        options.statusText = options.status === 200 ? 'ok' : 'not found';
        options.headers = headers;
        options.url = url;
        return options;
    }

    protected campaignHtml = `<!DOCTYPE html><html lang="en-US" style="background-color: transparent;"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1"><meta name="csrf-param" content="_csrf"><meta name="csrf-token" content="jKyaIIEtw5x7zGZcZeUA3BRvyrf5Z25Yp522kqGMVRnqm8Ba73eAwxyhSz8Ti1WNQj6-0p8FJhLgxffgyfxtbw=="><title>Upload Materials    </title><link rel="icon" href="/local-donut.ico" type="image/x-icon"><script> var determineHeight = function() {var height = document.body.scrollHeight;window.parent.postMessage({type: 'iframeHeight', height: height}, '*');if (window !== window.parent) {document.body.classList.add('iframe-content');document.documentElement.classList.add('iframe-content');}};window.onload = determineHeight;window.addEventListener('message', function(event) {if (event.data.type === 'iframeResize') determineHeight();});</script></head><body class="api-edit campaign/api-edit " style="background-color: transparent;"><style> :root {}  html.md-theme-default.iframe-content {--md-theme-default-background-variant: transparent;background-color: transparent !important;}  .iframe-content body {background-color: transparent !important;}  .iframe-content .container {margin: 0;}</style><div class="wrap"><div class="container"><div id="e-builder-edit-ads"></div><e-builder-edit-ads id="shadow-root" cid-override="49754" api-url="http://local.cloud.pmmimediagroup.com:8085"></e-builder-edit-ads></div></div><script src="/protected/basic/web/e-builder/js/e-builder.js?v=1720377966"></script></body></html>`;

    protected campaignConfirmationHtml = `<!DOCTYPE html><html lang="en-US" style="background-color: transparent;"><head><meta charset="UTF-8"><title>Materials Confirmation</title></head><body class="api-confirmation campaign/api-confirmation " style="background-color: transparent;"><div class="wrap"><div class="container"><div class="bg-warning" style="padding: 15px; text-align: center; background-color: #fcf8e3; font-family: Roboto,sans-serif;width: 1170px;">You’ve already approved your materials. If you would like to edit your placement please contact<strong>Reed            Simonsis</strong> (<a href="mailto:rsimonsis@pmmimediagroup.com">rsimonsis@pmmimediagroup.com</a>) and ask them to unlock your placement.</div><div id="e-builder-confirmation"></div><e-builder-confirmation id="shadow-root" cid-override="49754" api-url="http://local.cloud.pmmimediagroup.com:8085"></e-builder-confirmation></div></div><script src="/protected/basic/web/e-builder-confirmation/js/e-builder-confirmation.js?v=1720546533"></script></body></html>`;
}
