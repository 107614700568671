import { Record } from 'immutable';

import { Brand } from './campaign';
import { IUser, User } from './user';

export interface IMediaDiscoveryQuote {
    id: number;
    name: string;
    createdDate: number;
}
const mediaDiscoveryQuoteDefaultValues: IMediaDiscoveryQuote = {
    id: 0,
    name: '',
    createdDate: 0
};
const MediaDiscoveryQuoteRecord = Record<IMediaDiscoveryQuote>({
    ...mediaDiscoveryQuoteDefaultValues
});
export class MediaDiscoveryQuote extends MediaDiscoveryQuoteRecord implements IMediaDiscoveryQuote {
    constructor(props: IMediaDiscoveryQuote) {
        super(props);
    }

    get createdDateMillis(): number {
        return this.createdDate * 1000;
    }
}

export interface IAdvertiserBasic {
    aid: number;
    name: string;
}

export interface IAdvertiser {
    aid: number;
    name: string;
    brands: Brand[];
    baseIdEncrypted: string | null;
    mundoBaseIdEncrypted?: string | null;
    salesForceId: string | null;
    mediaDiscoveryQuotes?: IMediaDiscoveryQuote[];
    csm?: IUser | null;
    salesReps?: IUser[];
    enhancedAdvertiser?: boolean | null;
}
const advertiserDefaultValues: IAdvertiser = {
    aid: 0,
    name: '',
    brands: [],
    baseIdEncrypted: null,
    mundoBaseIdEncrypted: null,
    mediaDiscoveryQuotes: [],
    csm: null,
    salesReps: [],
    salesForceId: null,
    enhancedAdvertiser: false,
};
const AdvertiserRecord = Record<IAdvertiser>({
    ...advertiserDefaultValues
});
export class Advertiser extends AdvertiserRecord implements IAdvertiser {
    constructor(props: IAdvertiser) {
        // make deep copy of props to avoid modifying original object
        props = JSON.parse(JSON.stringify(props));
        if (props.mediaDiscoveryQuotes) {
            props.mediaDiscoveryQuotes = props.mediaDiscoveryQuotes.map(quote => new MediaDiscoveryQuote(quote));
        }
        if (props.csm) {
            props.csm = new User(props.csm);
        }
        if (props.salesReps) {
            props.salesReps = props.salesReps.map(salesRep => new User(salesRep));
        }
        super(props);
    }

    get csmEmail(): string {
        return this.csm?.email || '';
    }

    get salesForceUrl(): string {
        return `https://pmmidotorg.lightning.force.com/lightning/r/Account/${this.salesForceId}/view`;
    }
}
