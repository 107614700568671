import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';

import { ICampaign, ITradeShow, ITradeShowReport, IAdvertiser, } from 'model';
import { environment } from 'util';

export interface TradeShowReportsResponse {
    advertiser?: IAdvertiser;
    gamePlan: ITradeShowReport[];
    secondLook: ITradeShowReport[];
    facilitiesAndInfrastructure: ITradeShowReport[];
}

@Injectable({
    providedIn: 'root'
})
export class CampaignService {
    #apiUrl = environment.apiUrl;
    #apiGatewayUrl = environment.apiGatewayUrl;
    #tradeShowCache: ITradeShow[] | null = null;
    #upcomingCampaignsCache: { [key: number]: ICampaign[] } = {};
    #pastCampaignsCache: { [key: number]: ICampaign[] } = {};
    #tradeShowReportsCache: { [key: number]: TradeShowReportsResponse } = {};

    constructor(
        private http: HttpClient
    ) {}

    getUpcomingCampaigns(...aids: number[]): Observable<ICampaign[]> {
        if (aids.length > 1) {
            return this.getUpcomingCampaignsMultiple(aids);
        }

        const aid = aids[0];
        if (this.#upcomingCampaignsCache[aid]) {
            return of(this.#upcomingCampaignsCache[aid]);
        }

        return this.http.get<ICampaign[]>(`${this.#apiUrl}/_/api/campaign/upcoming/${aid}`).pipe(
            map((campaigns: ICampaign[]) => {
                this.#upcomingCampaignsCache[aid] = campaigns;
                return campaigns;
            })
        );
    }

    protected getUpcomingCampaignsMultiple(aids: number[]): Observable<ICampaign[]> {
        const { cached, remaining } = this.findCachedAndRemainingCampaigns(aids, this.#upcomingCampaignsCache);

        const endpoint = `${this.#apiGatewayUrl}/campaigns/upcoming?advertiserIds=${remaining.join(',')}`;

        return this.http.get<ICampaign[]>(endpoint, { withCredentials: true })
            .pipe(
                map((campaigns: ICampaign[]) => {
                    for (let campaign of campaigns) {
                        this.#upcomingCampaignsCache[campaign.aid] = this.#upcomingCampaignsCache[campaign.aid] || [];
                        this.#upcomingCampaignsCache[campaign.aid].push(campaign);
                    }

                    return cached.concat(campaigns);
                })
            );
    }

    getPastCampaigns(...aids: number[]): Observable<ICampaign[]> {
        if (aids.length > 1) {
            return this.getPastCampaignsMultiple(aids);
        }

        const aid = aids[0];
        if (this.#pastCampaignsCache[aid]) {
            return of(this.#pastCampaignsCache[aid]);
        }

        return this.http.get<ICampaign[]>(`${this.#apiUrl}/_/api/campaign/past/${aid}`).pipe(
            map((campaigns: ICampaign[]) => {
                this.#pastCampaignsCache[aid] = campaigns;
                return campaigns;
            })
        );
    }

    protected getPastCampaignsMultiple(aids: number[]): Observable<ICampaign[]> {
        const { cached, remaining } = this.findCachedAndRemainingCampaigns(aids, this.#pastCampaignsCache);

        const endpoint = `${this.#apiGatewayUrl}/campaigns/past?advertiserIds=${remaining.join(',')}`;

        return this.http.get<ICampaign[]>(endpoint, { withCredentials: true })
            .pipe(
                map((campaigns: ICampaign[]) => {
                    for (let campaign of campaigns) {
                        this.#pastCampaignsCache[campaign.aid] = this.#pastCampaignsCache[campaign.aid] || [];
                        this.#pastCampaignsCache[campaign.aid].push(campaign);
                    }

                    return cached.concat(campaigns);
                })
            );
    }

    getTradeShowReports(...aids: number[]): Observable<TradeShowReportsResponse> {
        if (aids.length > 1) {
            return this.getTradeShowReportsMultiple(aids);
        }

        const aid = aids[0];
        if (this.#tradeShowReportsCache[aid]) {
            return of(this.#tradeShowReportsCache[aid]);
        }

        return this.http.get<TradeShowReportsResponse>(`${this.#apiUrl}/_/api/campaign/past-trade-show/${aid}`).pipe(
            map((report: TradeShowReportsResponse) => {
                this.#tradeShowReportsCache[aid] = report;
                return report;
            })
        );
    }

    protected getTradeShowReportsMultiple(aids: number[]): Observable<TradeShowReportsResponse> {
        const { cached, remaining } = this.findCachedAndRemainingTradeShowReports(aids, this.#tradeShowReportsCache);

        const endpoint = `${this.#apiGatewayUrl}/campaigns/past-trade-show?advertiserIds=${remaining.join(',')}`;

        return this.http.get<TradeShowReportsResponse[]>(endpoint, { withCredentials: true })
            .pipe(
                map((reports: TradeShowReportsResponse[]) => {
                    for (let report of reports) {
                        if (!report.advertiser) { continue; }
                        this.#tradeShowReportsCache[report.advertiser.aid] = report;
                    }

                    return [ ...cached, ...reports ].reduce(
                        (acc, obj) => {
                            if (!obj) { return acc; }
                            return {
                                gamePlan: acc.gamePlan.concat(obj.gamePlan),
                                secondLook: acc.secondLook.concat(obj.secondLook),
                                facilitiesAndInfrastructure: acc.facilitiesAndInfrastructure.concat(obj.facilitiesAndInfrastructure),
                            };
                        },
                        { gamePlan: [], secondLook: [], facilitiesAndInfrastructure: [], } as TradeShowReportsResponse
                    );
                })
            );
    }

    getTradeShows(): Observable<ITradeShow[]> {
        if (this.#tradeShowCache) {
            return of(this.#tradeShowCache);
        }

        return this.http.get<ITradeShow[]>(`${this.#apiUrl}/_/api/campaign/trade-shows`).pipe(
            map((tradeShows: ITradeShow[]) => {
                this.#tradeShowCache = tradeShows;
                return tradeShows;
            })
        );
    }

    getExhibitorPortalTradeShows(): Observable<ITradeShow[]> {
        return this.getTradeShows().pipe(
            map((tradeShows: ITradeShow[]) => tradeShows.filter((tradeShow: ITradeShow) => tradeShow.active)),
            // now sort it so that `'showType': 'pei'` is always first, then `'showType': 'pelv'`, then `'showType': 'pxe'` then all others
            map((tradeShows: ITradeShow[]) =>  {
                const order = ['pei', 'pelv', 'pxe'];
                return tradeShows.sort((a: ITradeShow, b: ITradeShow) => {
                    const aIndex = order.indexOf(a.showType);
                    const bIndex = order.indexOf(b.showType);

                    // If both showTypes are in the order array
                    if (aIndex !== -1 && bIndex !== -1) {
                        return aIndex - bIndex;
                    }

                    // If only a is in the order array
                    if (aIndex !== -1) {
                        return -1;
                    }

                    // If only b is in the order array
                    if (bIndex !== -1) {
                        return 1;
                    }

                    // If neither is in the order array, maintain their relative order
                    return 0;
                });
            })
        );
    }

    getCampaignLeads(campaignId: number, forceLeadView: boolean = false): Observable<string> {
        let url = `${this.#apiUrl}/_/api/campaign/leads/${campaignId}`;
        if (forceLeadView) {
            url += '?forceLeadView=true';
        }
        return this.http.get<string>(url);
    }

    getCampaignEditPage(campaignId: number): Observable<string> {
        return this.http.get<string>(`${this.#apiUrl}/_/api/campaign/edit/${campaignId}`);
    }

    getCampaignConfirmationPage(campaignId: number): Observable<string> {
        return this.http.get<string>(`${this.#apiUrl}/_/api/campaign/confirmation/${campaignId}`);
    }

    getCampaignFacilitiesAndInfrastructurePage(advertiserId: number, eventId: number): Observable<string> {
        return this.http.get<string>(`${this.#apiUrl}/_/api/facilities-and-infrastructure/advertiser/${advertiserId}/event/${eventId}`);
    }

    protected findCachedAndRemainingCampaigns(
        aids: number[],
        cache: { [key: number]: ICampaign[] }
    ): { cached: ICampaign[], remaining: number[] } {
        let cached: ICampaign[] = [];
        let remaining: number[] = [];

        for (let aid of aids) {
            if (cache[aid]) {
                cached.push(...cache[aid]);
            } else {
                remaining.push(aid);
            }
        }

        return { cached, remaining };
    }

    protected findCachedAndRemainingTradeShowReports(
        aids: number[],
        cache: { [key: number]: TradeShowReportsResponse }
    ): { cached: TradeShowReportsResponse[], remaining: number[] } {
        let cached: TradeShowReportsResponse[] = [];
        let remaining: number[] = [];

        for (let aid of aids) {
            if (cache[aid]) {
                cached.push(cache[aid]);
            } else {
                remaining.push(aid);
            }
        }

        return { cached, remaining };
    }
}
