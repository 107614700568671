import { Routes } from '@angular/router';
import { AnalysisComponent, CampaignComponent, CampaignPageType } from 'content/campaign';
import { ContactDirectoryComponent } from 'content/contact-directory/contact-directory.component';
import { ExhibitorPortalComponent } from 'content/exhibitor-portal/exhibitor-portal.component';
import {
    GamePlanSecondLook,
    GamePlanSecondLookComponent
} from 'content/game-plan-second-look/game-plan-second-look.component';
import { MediaDiscoveryReviewComponent, MediaDiscoveryToolComponent } from 'content/media-discovery-tool';
import { MyAdvertisersComponent } from 'content/my-advertisers/my-advertisers.component';
import { NotFoundComponent } from 'content/not-found/not-found.component';
import { PastCampaignsComponent, PastCampaignsPageType, } from 'content/past-campaigns/past-campaigns.component';
import { ReportsAndToolsComponent } from 'content/reports-and-tools/reports-and-tools.component';
import { RouterHubComponent } from 'content/router-hub/router-hub.component';
import { UpcomingCampaignsComponent, SalesRepUpcomingCampaignsComponent, } from 'content/upcoming-campaigns';
import { RootComponent } from 'layouts/root/root.component';
import { LoginComponent } from './content/login/login.component';
import { UseLoginTokenComponent } from './content/use-login-token/use-login-token.component';
import { PasswordComponent } from './content/password/password.component';
import { ForgotPasswordComponent } from './content/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './content/reset-password/reset-password.component';

export const routes: Routes = [
    {
        path: 'advertiser/:advertiserId',
        component: RootComponent,
        children: [
            { path: 'campaign/:campaignId', component: CampaignComponent },
            {
                path: 'upcoming-campaigns',
                children: [
                    { path: '', component: UpcomingCampaignsComponent },
                    {
                        path: 'campaign/:campaignId',
                        component: CampaignComponent,
                        data: { isPastCampaign: false, campaignPageType: CampaignPageType.Leads }
                    },
                    {
                        path: 'campaign/:campaignId/edit',
                        component: CampaignComponent,
                        data: { isPastCampaign: false, campaignPageType: CampaignPageType.Edit }
                    },
                    {
                        path: 'campaign/:campaignId/confirmation',
                        component: CampaignComponent,
                        data: { isPastCampaign: false, campaignPageType: CampaignPageType.Confirmation }
                    },
                ],
            },
            {
                path: 'past-campaigns',
                children: [
                    {
                        path: '',
                        component: PastCampaignsComponent,
                        data: { pageType: PastCampaignsPageType.Advertiser, }
                    },
                    {
                        path: 'campaign/:campaignId',
                        component: CampaignComponent,
                        data: { isPastCampaign: true, campaignPageType: CampaignPageType.Leads }
                    },
                    {
                        path: 'campaign/:campaignId/leads',
                        component: CampaignComponent,
                        data: { isPastCampaign: true, campaignPageType: CampaignPageType.Leads, forceLeadView: true }
                    },
                    {
                        path: 'campaign/:campaignId/analysis',
                        component: AnalysisComponent,
                    },
                    {
                        path: 'facilities-and-infrastructure/:eventId',
                        component: CampaignComponent,
                        data: { isPastCampaign: true, campaignPageType: CampaignPageType.FacilitiesAndInfrastructure }
                    },
                    {
                        path: 'game-plan/event/:eventId/category/:categoryId',
                        component: GamePlanSecondLookComponent,
                        data: { isPastCampaign: true, pageType: GamePlanSecondLook.GamePlan }
                    },
                    {
                        path: 'second-look/event/:eventId/category/:categoryId',
                        component: GamePlanSecondLookComponent,
                        data: { isPastCampaign: true, pageType: GamePlanSecondLook.SecondLook }
                    }
                ]
            },
            {
                path: 'reports-and-tools',
                children: [
                    { path: '', component: ReportsAndToolsComponent },
                    {
                        path: 'media-discovery-tool',
                        children: [
                            { path: '', component: MediaDiscoveryToolComponent },
                            { path: 'review/:quoteId', component: MediaDiscoveryReviewComponent },
                        ],
                    },
                ],
            },
            { path: 'exhibitor-portal', component: ExhibitorPortalComponent, },
            { path: 'contacts-directory', component: ContactDirectoryComponent },
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'use-login-token',
        component: UseLoginTokenComponent,
    },
    {
        path: 'password',
        component: PasswordComponent,
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'user/:userId',
        component: RootComponent,
        children: [
            {
                path: 'my-advertisers',
                children: [
                    { path: '', component: MyAdvertisersComponent },
                    { path: 'upcoming-campaigns', component: SalesRepUpcomingCampaignsComponent },
                    {
                        path: 'past-campaigns',
                        component: PastCampaignsComponent,
                        data: { pageType: PastCampaignsPageType.SalesRep }
                    }
                ],
            },
        ],
    },
    {
        path: '',
        component: RootComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: '404', component: NotFoundComponent },
            { path: 'home', component: RouterHubComponent, },
        ],
    }
];
