<form [formGroup]="form">
    <mat-form-field appearance="outline">
        <mat-label>SEARCH COMPANY NAME</mat-label>
        <input type="text" matInput formControlName="advertiserInput" [matAutocomplete]="auto">
        <mat-icon matSuffix>search</mat-icon>
        <mat-autocomplete #auto="matAutocomplete"
                          (optionSelected)="onOptionSelected($event.option.value)"
                          [displayWith]="displayFn"
        >
            @for (advertiser of filteredAdvertisers | async; track advertiser) {
                <mat-option [value]="advertiser">
                    {{ advertiser.name }}
                </mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
</form>
