import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { RouterLink } from '@angular/router';
import { MatTableModule, } from '@angular/material/table';

import { AdvertiserAutocompleteComponent } from 'components/advertiser-autocomplete/advertiser-autocomplete.component';
import { Breadcrumb, BreadcrumbComponent } from 'components/breadcrumb/breadcrumb.component';
import { WrapperTableComponent, FilterInputDirective } from 'components/wrapper-table';
import { Advertiser, IAdvertiserBasic } from 'model';
import { map, Observable } from 'rxjs';
import { UserService } from 'services';
import { AsTypePipe, BrandsPipe, environment } from 'util';

@Component({
    selector: 'app-my-advertisers',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        BreadcrumbComponent,
        WrapperTableComponent,
        MatTableModule,
        MatSortModule,
        AsTypePipe,
        RouterLink,
        BrandsPipe,
        AdvertiserAutocompleteComponent,
        FilterInputDirective,
        MatIconModule,
        FlexLayoutModule,
        MatButtonModule,
    ],
    templateUrl: './my-advertisers.component.html',
    styleUrl: './my-advertisers.component.scss'
})
export class MyAdvertisersComponent {
    protected user$ = this.userService.getLoggedInUser();

    protected breadcrumbs$: Observable<Breadcrumb[]> = this.user$.pipe(
        map(user => [
            { label: `${user.firstName} ${user.lastName}`, url: '' },
            { label: 'My Advertisers', url: '' },
        ])
    );

    protected dataToDisplay$: Observable<Advertiser[]> = this.user$.pipe(
        map(user => user.sortedAdvertisers as Advertiser[])
    );

    protected columns$: Observable<string[]> = this.user$.pipe(
        map(user => user.isAdvertiser ? ['name', 'brands', 'engagementReports'] : ['name', 'brands', 'salesforce', 'engagementReports', 'update'])
    );

    @ViewChild(WrapperTableComponent) protected table!: WrapperTableComponent<Advertiser>;

    protected readonly internalUrl: string = environment.apiUrl;

    constructor(
        private userService: UserService
    ) {}

    protected readonly Advertiser = Advertiser;

    protected handleAdvertiserSelected(advertiser: IAdvertiserBasic | null): void {
        if (!advertiser) {
            this.table.filterTable([]);
        } else {
            this.table.filterTable([{property: 'aid', value: advertiser.aid}]);
        }
    }
}
